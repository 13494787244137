import axios from 'axios'
import qs from 'qs'
import { gsap } from 'gsap'

const pastEvents = {
  get() {
    // We get the last div id with class: no-article and we sanitize it.
    const muzikeSection = document.querySelector('.muzike-section--past-events')
    const noArticleElms = document.querySelectorAll('.past-events__inner__single')
    const noArticleId = noArticleElms[noArticleElms.length - 1].id
    const noArticleIdClean = noArticleId.replace('post-', '')
    let eventIds = []

    noArticleElms.forEach(item => {
      const eventId = item.id
      const eventIdClean = eventId.replace('post-', '')

      eventIds.push(eventIdClean)
    })

    //console.log(eventIds)

    document.querySelector('.load-more-past-events').classList.add('loading')

    axios
      .post(
        myAjax.ajaxurl,
        qs.stringify({
          action: 'past_events',
          _ajax_nonce: myAjax.nonce,
          curPostId: noArticleIdClean,
          excludeIds: eventIds,
          category: muzikeSection.getAttribute('data-category-id')
        })
      )
      .then(function (response) {
        const data = response.data
        const $response = data.data
        /**
         * Checking if we retrieved the last post,
         * cause with wp we always get the previous post we need a stop,
         * so if we retrived the last one, we forbid to append it.
         */

        if (data.success) {
          const elem = document.querySelector('.past-events__inner')
          elem.innerHTML += $response.article

          //console.log(data)

          gsap.fromTo(
            '.past-events__inner__single.entering',
            {
              y: 150,
              opacity: 0
            },
            {
              y: 0,
              opacity: 1,
              stagger: 0.2,
              onComplete: function () {
                document.querySelector('.load-more-past-events').classList.remove('loading')
                this.targets().forEach(item => item.classList.remove('entering'))
              }
            }
          )
        } else {
          document.querySelector('.load-more-past-events').classList.remove('loading')
          if ($response.noposts) document.querySelector('.load-more-past-events').remove()
        }
      })
  }
}

export default pastEvents
