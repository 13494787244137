import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded'
import axios from 'axios'
import qs from 'qs'
import { gsap } from 'gsap'
import galleryOverlay from './galleryOverlay'

const globalGallery = {
  Masonry() {
    const elem = document.querySelector('.grid')

    if (!elem) return

    galleryOverlay.init()
    this.msnry = new Masonry(elem, {
      // options
      itemSelector: '.grid-item'
    })

    setTimeout(() => {
      this.msnry.layout()
      setTimeout(() => {
        this.msnry.layout()
      }, 1000)
    }, 1000)
  },

  GetMoreGallery(getall = 'false') {
    const _this = this
    const pageId = document.body.id
    const galIdElms = document.querySelectorAll('.gallery-masonry .grid-item')
    const galId = galIdElms[galIdElms.length - 1].id
    const galIdClean = galId.replace('gal-', '')
    const loadMoreGallery = document.querySelector('.load-more-gallery')

    if (!loadMoreGallery) return

    loadMoreGallery.classList.add('loading')

    axios
      .post(
        myAjax.ajaxurl,
        qs.stringify({
          action: 'gallery',
          _ajax_nonce: myAjax.nonce,
          page_id: pageId,
          gal_id: galIdClean
          // getall
        })
      )
      .then(function (response) {
        const data = response.data
        const $response = data.data
        /**
         * Checking if we retrieved the last post,
         * cause with wp we always get the previous post we need a stop,
         * so if we retrived the last one, we forbid to append it.
         */

        if (data.success) {
          const elem = document.querySelector('.gallery-masonry')
          const loadMoreBtn = loadMoreGallery
          gsap.globalTimeline.clear()

          if ($response.haveImgs) return false

          elem.innerHTML += $response.content
          _this.msnry.appended(document.querySelectorAll('.xoxo'))

          var posts = document.querySelectorAll('.xoxo')
          imagesLoaded(posts).on('done', function (instance) {
            _this.msnry.reloadItems()
            galleryOverlay.init()

            gsap.fromTo(
              '.grid-item.xoxo img',
              {
                y: 50,
                scale: 0.95,
                opacity: 0
              },
              {
                y: 0,
                opacity: 1,
                scale: 1,
                stagger: 0.2,
                onUpdate: () => _this.msnry.layout(),
                onComplete: function () {
                  if ($response.finished) loadMoreBtn.remove()
                  if (loadMoreBtn) loadMoreBtn.classList.remove('loading')

                  this.targets().forEach(item => item.parentNode.classList.remove('xoxo'))
                  gsap.set('.grid-item img', {
                    opacity: 1
                  })
                }
              }
            )

            elem.querySelectorAll('.grid-item').forEach((item, i) => {
              item.dataset.num = i
            })
          })
        } else {
          loadMoreGallery.classList.remove('loading')
        }
      })
  }
}
export default globalGallery
