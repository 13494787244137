// Libraries
import Glide from '@glidejs/glide'
import Parallax from 'parallax-js'

const home = {
  init() {
    if (!document.body.classList.contains('page-template-homepage')) return
    this.homeSlider()
    this.homeEventsSlider()
    this.homeGalleryParallax()
  },

  homeGalleryParallax() {
    const metaImg = document.querySelectorAll('.upcoming-event__content__meta__img')
    metaImg.forEach(item => {
      var parallaxInstance = new Parallax(item)
    })
  },

  homeSlider() {
    if (!document.querySelector('.glide')) return false

    new Glide('.glide').mount()
  },

  homeEventsSlider() {
    const slider = document.querySelector('.events__slider')
    const slides = document.querySelectorAll('.events__slider .glide__slide').length

    if (!slider) return false

    console.log(slides)

    new Glide(slider, {
      perView: 2,
      type: slides > 1 ? 'carousel' : 'slider',
      startAt: 0,
      gap: '100',
      breakpoints: {
        768: {
          perView: 1
        }
      }
    }).mount()
  }
}
export default home
