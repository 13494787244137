// Libraries
import './polyfills'
import Vivus from 'vivus'
import { gsap } from 'gsap'
import Glide from '@glidejs/glide'
import rua from './removeUppercaseAccents'

// Bootstrap
import Modal from './bootstrap/src/modal'
import './bootstrap/src/collapse'
import './bootstrap/src/dropdown'

// Classes
import home from './class-home'

// Components
import globalGallery from './globalGallery'
import pastEvents from './pastEvents'

class app {
  constructor() {
    let msnry = ''
    let scrollbar = ''
    let isMobile = true

    this.globalPreload()
    document.querySelector('html').classList.add('getting-data')

    window.onload = event => {
      document.querySelector('html').classList.remove('getting-data')
      document.body.classList.add('all-loaded')
      document.body.style.overflow = ''

      setTimeout(() => {
        gsap.fromTo(
          '.muzike-loader-bg',
          {
            top: 'auto'
          },
          {
            height: 0,
            delay: 3,
            stagger: 0.2,
            onStart: () => {
              gsap.set('.muzike-loader', {
                backgroundColor: 'transparent'
              })
              gsap.to('#muzike-loader-logo', {
                y: '100%',
                autoAlpha: 0
              })
            },
            onComplete: () => {
              gsap.to('.muzike-loader', {
                autoAlpha: 0,

                onComplete: () => {
                  if (window.innerWidth > 991) {
                    globalGallery.GetMoreGallery(true)
                  }
                }
              })

              const elmnt = document.getElementById('modal-home')
              if (elmnt) {
                const myModal = new Modal(elmnt)
                myModal.show()
                document.querySelector('#modal-home button').addEventListener('click', () => myModal.hide(), false)
              }
            }
          }
        )
      }, 250)
      this.headerToTop()
      this.globalParallaxImg()
      globalGallery.Masonry()
      this.globalInteractions()
      this.globalLines()
      home.init()
      this.miniGallery()
      rua.init()
      this.contactForm()
    }

    window.addEventListener('resize', ['this', 'appHeight'])
    this.appHeight()
  }

  contactForm() {
    const page = document.querySelector('body.page-template-contactpage')
    if (!page) return

    const dropdowns = document.querySelectorAll('form .dropdown')
    const dropdownLinks = document.querySelectorAll('form .dropdown a')

    dropdownLinks.forEach(dropdownLink =>
      dropdownLink.addEventListener('click', e => {
        const button = dropdownLink.parentNode.parentNode.querySelector('button')
        const input = document.getElementById(button.dataset.inputid)

        e.preventDefault()
        button.textContent = dropdownLink.textContent
        input.value = dropdownLink.textContent
      })
    )
  }

  miniGallery() {
    const el = '.mini-gallery__inner'
    let options = {}

    if (!document.querySelector(el)) return false

    if (document.querySelector(el).dataset.doautoplay) {
      options = {
        autoplay: 5000,
        hoverpause: true
      }
    }

    new Glide(el, options).mount()
  }

  appHeight() {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  globalPreload() {
    document.body.style.overflow = 'hidden'

    new Vivus(
      'muzike-loader-logo',
      {
        file: myAjax.distUri + '/svg/logo-outline.svg',
        duration: 200,
        animTimingFunction: Vivus.EASE_OUT_BOUNCE
      },
      function () {
        gsap.fromTo(
          '#' + this.parentEl.id + ' .st0',
          {
            fill: 'transparent'
          },
          {
            fill: '#d1185f',
            repeat: -1
          }
        )
      }
    )

    gsap.set('.muzike-mobile-menu', {
      autoAlpha: 0
    })
  }

  isScrolledIntoView(el, centered) {
    var rect = el.getBoundingClientRect()
    var elemTop = rect.top
    var elemBottom = rect.bottom
    var isVisible

    if (this.isMobile) {
      // Only completely visible elements return true:
      isVisible = centered
        ? elemTop - 300 < window.innerHeight && elemBottom >= 0
        : elemTop < window.innerHeight && elemBottom
    } else {
      // Only completely visible elements return true:
      // ΜΑΥΒΕ? //isVisible = centered ? ((el.getBoundingClientRect().top -  (window.innerHeight + 300) ) * -1) >=  (el.getBoundingClientRect().height / 2) : elemTop < noImgFound.scrollbar.limit.y && elemBottom
      // MABYE2? isVisible = centered ? el.getBoundingClientRect().top <= (window.innerHeight + 300) / 2 : elemTop < noImgFound.scrollbar.limit.y && elemBottom
      isVisible = el.getBoundingClientRect().top <= window.innerHeight + 150
    }

    // Partially visible elements return true:
    //isVisible = elemTop < window.innerHeight && elemBottom >= 0;

    return isVisible
  }

  globalLines() {
    if (!document.body.classList.contains('page-template-homepage')) return false

    let lineEl = {}
    const lines = [
      ['muzike-title-line1', 'section-title-line1.svg'],
      ['muzike-title-line2', 'section-title-line2.svg'],
      ['muzike-title-line3', 'section-title-line3.svg'],
      ['muzike-title-line4', 'section-title-line1.svg']
    ]

    lines.forEach((item, index) => {
      if (document.getElementById(item[0])) {
        lineEl[item[0]] = new Vivus(item[0], {
          file: myAjax.distUri + '/svg/' + item[1],
          duration: 150
        })
      }
    })
  }

  globalMobileMenu() {
    document.body.classList.toggle('open-menu')
    if (document.body.classList.contains('open-menu')) {
      gsap.fromTo(
        '.muzike-mobile-menu',
        {
          autoAlpha: 1,
          y: '-100%'
        },
        {
          y: '0',
          duration: 0.8
        }
      )

      gsap.fromTo(
        '.muzike-mobile-menu .menu-item',
        {
          autoAlpha: 0,
          rotate: '-2deg',
          y: 60
        },
        {
          y: '0',
          rotate: '0',
          autoAlpha: 1,
          stagger: 0.15
        }
      )
    } else {
      gsap.to('.muzike-mobile-menu', {
        autoAlpha: 0
      })
    }
  }

  headerToTop() {
    if (!document.body.classList.contains('page-template-homepage')) return

    window.addEventListener('scroll', () => {
      const amountScrolled = window.pageYOffset
      const windowHeight = window.innerHeight - 110

      if (amountScrolled >= windowHeight) {
        document.body.classList.add('header-to-top')
      } else {
        document.body.classList.remove('header-to-top')
      }
    })
  }

  globalParallaxImg() {
    window.addEventListener('scroll', e => {
      const scrolled = window.pageYOffset
      const backgrounds = document.querySelectorAll('.prlx')

      backgrounds.forEach(item => (item.style.backgroundPositionY = -(scrolled * 0.2) + 'px'))
    })
  }

  globalInteractions() {
    document.addEventListener(
      'click',
      event => {
        // If the clicked element doesn't have the right selector, bail
        if (!event.target.matches('.muzike-nav-btn')) return

        event.preventDefault()
        this.globalMobileMenu()
      },
      false
    )

    document.addEventListener(
      'click',
      event => {
        // If the clicked element doesn't have the right selector, bail
        if (!event.target.matches('.load-more-past-events')) return

        event.preventDefault()
        document.querySelector('.load-more-past-events').classList.add('loading')
        pastEvents.get()
      },
      false
    )

    document.addEventListener(
      'click',
      event => {
        // If the clicked element doesn't have the right selector, bail
        if (!event.target.matches('.load-more-gallery')) return

        // Don't follow the link
        event.preventDefault()
        globalGallery.GetMoreGallery()
      },
      false
    )
  }
}

export default app
