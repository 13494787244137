import globalGallery from './globalGallery'

const galleryOverlay = {
  el: '',
  elOverlayImg: '',
  elImgs: '',
  elClose: '',
  elNav: '',
  btnListened: false,
  currentID: 0,
  init: function () {
    this.el = document.querySelector('.gallery-overlay')
    this.elOverlayImg = document.querySelector('.gallery-overlay__img')
    this.elImgs = document.querySelectorAll('.gallery-masonry .grid-item img')
    this.elClose = document.querySelector('.gallery-overlay__controls .close')
    this.elNav = document.querySelectorAll('.gallery-overlay__controls .nav')

    if (!this.el) return false

    this.getImgs()
    this.closeLayout()
    this.navigation()
  },
  getImgs: function () {
    this.elImgs.forEach(img => {
      img.addEventListener('click', e => {
        const allImages = document.querySelectorAll('.gallery-masonry .grid-item')

        e.preventDefault()

        allImages.forEach(img => {
          img.classList.remove('active')
        })

        img.parentElement.classList.add('active')

        this.loadMore(img.parentElement)

        document.body.classList.add('gallery-overlay-open')

        this.elOverlayImg.style.backgroundImage = `url(${img.src})`
        this.elOverlayImg.dataset.id = img.parentElement.id
        this.currentID = img.parentElement.id
      })
    })
  },
  closeLayout: function () {
    this.elClose.addEventListener('click', () => document.body.classList.remove('gallery-overlay-open'))
  },
  navigation: function () {
    if (this.btnListened) return
    this.btnListened = true

    this.elNav.forEach(btn => {
      btn.addEventListener('click', e => {
        this.elOverlayImg = document.querySelector('.gallery-overlay__img')
        const imgId = this.elOverlayImg.dataset.id
        const allImages = document.querySelectorAll('.gallery-masonry .grid-item')
        let newImg

        e.preventDefault()

        if (btn.classList.contains('prev')) {
          newImg = document.getElementById(imgId).previousElementSibling
        } else {
          newImg = document.getElementById(imgId).nextElementSibling
        }

        this.elOverlayImg.dataset.id = this.currentID

        if (newImg) {
          this.loadMore(newImg)

          allImages.forEach(img => {
            img.classList.remove('active')
          })

          newImg.classList.add('active')

          newImg = newImg.querySelector('img')
          this.elOverlayImg.style.backgroundImage = `url(${newImg.src})`
          this.elOverlayImg.dataset.id = newImg.parentElement.id
          this.currentID = newImg.parentElement.id
        }
      })
    })
  },
  loadMore(img) {
    const allImages = document.querySelectorAll('.gallery-masonry .grid-item')
    const total = allImages.length

    if (img.dataset.num >= total - 6) {
      globalGallery.GetMoreGallery()
    }
  }
}
export default galleryOverlay
