// Libraries
import './polyfills'
import { gsap } from 'gsap'

// Classes
import app from './class-app'
;(function() {
  const muzikeApp = new app()
})()

// Window Events
window.addEventListener('beforeunload', () => {
  gsap.fromTo(
    '.muzike-loader-bg',
    {
      top: '0'
    },
    {
      height: '100%',
      stagger: -0.2,
      onStart: () => gsap.set('.muzike-loader', { autoAlpha: 1 }),
      onComplete: () => new Promise(done => done())
    }
  )
})
window.addEventListener(
  'scroll',
  () => {
    const scrollTop =
      window.pageYOffset !== undefined
        ? window.pageYOffset
        : (document.documentElement || document.body.parentNode || document.body).scrollTop

    if (scrollTop > 0) document.body.classList.add('has-scrolled')
    else document.body.classList.remove('has-scrolled')
  },
  false
)
